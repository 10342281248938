import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import logo from '../image/logo.png'
import FormContext from "./FormContext";



const Fromtwo = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const { data, updateData, updateStep } = useContext(FormContext);
  const [emptyFields, setEmptyFields] = useState([]);
  const handleChange = (event) => {
    updateData({ ...data, [event.target.name]: event.target.value });
  };

  const handleNext = () => {
    const requiredFields = [
      'business_name',
      'ein_number',
      'physical_city',
      'physical_state',
      'physical_street_address',
      'physical_zip_code',
      'state_of_formation',
    ];

    const emptyFields = requiredFields.filter((field) => !data[field] || data[field] === '');

    if (emptyFields.length > 0) {
      toast.error(`Please fill in the following fields: ${emptyFields.join(', ')}`);
      setEmptyFields(emptyFields);
      return;
    }

    updateData({
      ...data,
    });
    updateStep(3);
  };

  const handleBack = () => {
    updateStep(1);
  };

  console.log(data, "form 2")


  return (
    <div className=" js-active " data-animation="slideVert">
      <div className="inner pb-100">
        <div className="wizard-content-item text-center pt-4 pb-4 d-flex justify-content-center align-items-center flex-wrap " style={{ gap: '10px' }}>
          <img src={logo} width={'100px'} alt="logo" />
          <h2>Business Information</h2>
        </div>
        <div className="wizard-content-form">
          <div className="wizard-form-field">
            <div className="wizard-form-input position-relative form-group has-float-label">
              <i
                data-toggle="tooltip"
                data-placement="bottom"
                title="If you want your invoice address to a compnay. Leave blank to use full name"
                className="fa fa-info"
              />
              <input
                type="text"
                name="business_name"
                onChange={handleChange}
                className="form-control"
                defaultValue={data.business_name}
                value={data.business_name}
                placeholder="Business Name*"
              />
              <label>Business Name*</label>
            </div>
            {emptyFields.includes('business_name') && (
              <p className="text-danger">business Name is required.</p>
            )}
            <div className="row">
              <div className="col-md-8">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <i
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="If you want your invoice address to a compnay. Leave blank to use full name"
                    className="fa fa-info"
                  />
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    name="ein_number"
                    defaultValue={data.ein_number}
                    value={data.ein_number}
                    placeholder="EIN Number"
                  />
                  <label>EIN Number </label>
                </div>
                <p> (If the business does not have an assigned EIN use owners Social Security Number)</p>
                {emptyFields.includes('ein_number') && (
                  <p className="text-danger">EIN Number is required.</p>
                )}
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                  <select
                    id="country"
                    onChange={handleChange}
                    name="state_of_formation"
                    defaultValue={data.state_of_formation}
                    value={data.state_of_formation}
                    className="form-control"
                  >
                    <option selected disabled value="">
                      State of Formation*
                    </option>
                    {usStates.map((data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {emptyFields.includes('state_of_formation') && (
                  <p className="text-danger">state of formation is required.</p>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-12   pt-4 pb-4">
                <h3>Business Physical Address*</h3>
              </div>
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    name="physical_street_address"
                    onChange={handleChange}
                    defaultValue={data.physical_street_address}
                    value={data.physical_street_address}
                    placeholder="Street Address"
                  />
                  <label>Street Address</label>
                </div>
                {emptyFields.includes('physical_street_address') && (
                  <p className="text-danger">physical street address is required.</p>
                )}
              </div>
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    name="physical_street_address_line_2"
                    onChange={handleChange}
                    defaultValue={data.physical_street_address_line_2}
                    value={data.physical_street_address_line_2}
                    placeholder=" Street Address Line 2"
                  />
                  <label>Street Address Line 2</label>
                </div>

              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    name="physical_city"
                    onChange={handleChange}
                    defaultValue={data.physical_city}
                    value={data.physical_city}
                    placeholder="City"
                  />
                  <label>City</label>
                </div>
                {emptyFields.includes('physical_city') && (
                  <p className="text-danger">physical city is required.</p>
                )}
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                  <select
                    id="country"
                    name="physical_state"
                    onChange={handleChange}
                    defaultValue={data.physical_state}
                    value={data.physical_state}
                    className="form-control"
                  >
                    <option selected disabled value="">
                      State
                    </option>
                    {usStates?.map((data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {emptyFields.includes('physical_state') && (
                  <p className="text-danger">physical state code is required.</p>
                )}
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="number"
                    className="form-control"
                    name="physical_zip_code"
                    onChange={handleChange}
                    defaultValue={data.physical_zip_code}
                    value={data.physical_zip_code}
                    placeholder="Zip/Postal Code"
                  />
                  <label>Zip/Postal Code</label>
                </div>
                {emptyFields.includes('physical_zip_code') && (
                  <p className="text-danger">physical zip code is required.</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12   pt-4 pb-4">
                <h3>
                  Business Mailing Address (If Different Than Physical Address)
                </h3>
              </div>
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    defaultValue={data.mailing_street_address}
                    value={data.mailing_street_address}
                    name="mailing_street_address"
                    placeholder="Street Address"
                  />
                  <label>Street Address</label>
                </div>
                {/* {emptyFields.includes('mailing_street_address') && (
                  <p className="text-danger">mailing street address is required.</p>
                )} */}
              </div>
              <div className="col-md-6">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    defaultValue={data.mailing_street_address_line_2}
                    value={data.mailing_street_address_line_2}
                    name="mailing_street_address_line_2"
                    placeholder=" Street Address Line 2"
                  />
                  <label>Street Address Line 2</label>
                </div>
                
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    defaultValue={data.mailing_city}
                    value={data.mailing_city}
                    name="mailing_city"
                    placeholder="City"
                  />
                  <label>City</label>
                </div>
                {/* {emptyFields.includes('mailing_city') && (
                  <p className="text-danger">mailing city is required.</p>
                )} */}
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                  <select
                    id="country"
                    onChange={handleChange}
                    name="mailing_state"
                    defaultValue={data.mailing_state}
                    value={data.mailing_state}
                    className="form-control"
                  >
                    <option selected disabled value="">
                      State
                    </option>
                    {usStates.map((data, index) => {
                      return (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* {emptyFields.includes('mailing_state') && (
                  <p className="text-danger">mailing state is required.</p>
                )} */}
              </div>
              <div className="col-md-4">
                <div className="wizard-form-input position-relative form-group has-float-label">
                  <input
                    type="number"
                    className="form-control"
                    name="mailing_zip_code"
                    onChange={handleChange}
                    defaultValue={data.mailing_zip_code}
                    value={data.mailing_zip_code}
                    placeholder="Zip/Postal Code"
                  />
                  <label>Zip/Postal Code</label>
                </div>
                {/* {emptyFields.includes('mailing_zip_code') && (
                  <p className="text-danger">mailing zip code is required.</p>
                )} */}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="actions">
            <ul className="d-flex justify-content-center align-items-center flex-wrap">
              <li onClick={handleBack}>
                <span className="js-btn-prev" title="BACK">
                  <i className="fa fa-arrow-left" /> BACK
                </span>
              </li>
              <li
                onClick={handleNext}
              >
                <span className="js-btn-next" title="NEXT">
                  NEXT <i className="fa fa-arrow-right" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fromtwo;
