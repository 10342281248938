/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-lone-blocks */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import logo from "../image/logo.png";
import Swal from "sweetalert2";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import FormContext from "./FormContext";

const Fromfour = ({ onClick }) => {
  const [helcimtoken, setHelcimToken] = useState("");
  const [paymentID, setPaymentID] = useState("");
  const [open, setOpen] = useState(false);

  const ref = useRef();

  const HelciumRef = () => {
    ref.current.click();
    setsloader(false);
  };

  useEffect(() => {
    if (activeMerchant?.name === "Merchant F") {
      handleSubmitHelcim();
    }
    window.scroll(0, 0);
  }, []);

  const activeMerchant = JSON.parse(localStorage.getItem("activeMerchant"));

  const { data, updateData, updateStep } = useContext(FormContext);
  const signatureRef = useRef();
  const [segnatoure, setsegnatoure] = useState("");

  const [loader, setsloader] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [Fields, setFields] = useState([
    "filling_fee_first_name",
    "filling_fee_last_name",
    "filling_fee_email",
    "zipcode",
  ]);

  console.log();
  const monthsArray = useMemo(
    () => [
      { label: "January", value: 1 },
      { label: "February", value: 2 },
      { label: "March", value: 3 },
      { label: "April", value: 4 },
      { label: "May", value: 5 },
      { label: "June", value: 6 },
      { label: "July", value: 7 },
      { label: "August", value: 8 },
      { label: "September", value: 9 },
      { label: "October", value: 10 },
      { label: "November", value: 11 },
      { label: "December", value: 12 },
    ],
    []
  );

  const Navigate = useNavigate();

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    const signatureData = signatureRef.current.toDataURL();
    setsegnatoure(signatureData);
    // You can now store `signatureData` in your state, send it to a server, or handle it as needed.
  };

  const handleChange = (event) => {
    updateData({ ...data, [event.target.name]: event.target.value });
  };
  const requiredFields = [
    "filling_fee_first_name",
    "filling_fee_last_name",
    "filling_fee_email",

    "zipcode",
    "card_number",
    "cvv",
    "name_on_card",
    "expiration_month",
    "expiration_year",
  ];
  const requiredFields1 = [
    "filling_fee_first_name",
    "filling_fee_last_name",
    "filling_fee_email",
  ];
  const requiredFields2 = [
    "filling_fee_first_name",
    "filling_fee_last_name",
    "filling_fee_email",
    "zipcode",
  ];

  const checkField = () => {
    const emptyFields = requiredFields2.filter(
      (field) => !data[field] || data[field] === ""
    );
    toast.error(
      `Please fill in the following fields: ${emptyFields.join(", ")}`
    );
    setEmptyFields(emptyFields);
    setFields(emptyFields)
    return;
  };


  const handleSubmit = (token) => {
    if (activeMerchant?.name === "Merchant F") {
      setOpen(true);
    }
    console.log(token?.id, "stripe ");

    if (activeMerchant?.name === "Merchant E") {
      if (emptyFields.length > 0) {
        const emptyFields = requiredFields1.filter(
          (field) => !data[field] || data[field] === ""
        );
        toast.error(
          `Please fill in the following fields: ${emptyFields.join(", ")}`
        );
        setEmptyFields(emptyFields);
        return;
      }
    } else {
      if (emptyFields.length > 0) {
        const emptyFields = requiredFields.filter(
          (field) => !data[field] || data[field] === ""
        );
        toast.error(
          `Please fill in the following fields: ${emptyFields.join(", ")}`
        );
        setEmptyFields(emptyFields);
        return;
      }
    }

    setsloader(true);

    // First, handle Square payment
    try {
      // await handleSquarePayment();
      const formDataa = new FormData();

      formDataa.append("notice_id", data?.notice_id);
      formDataa.append(
        "submitter_business_name",
        data?.submitter_business_name
      );
      formDataa.append("submitter_first_name", data?.submitter_first_name);
      formDataa.append("submitter_last_name", data?.submitter_last_name);
      formDataa.append("submitter_email", data?.submitter_email);
      formDataa.append("submitter_role", data?.submitter_role);
      formDataa.append("signature", data?.signature);
      formDataa.append("business_name", data?.business_name);
      formDataa.append("filling_fee_first_name", data.filling_fee_first_name);
      formDataa.append("filling_fee_last_name", data.filling_fee_last_name);
      formDataa.append("filling_fee_email", data.filling_fee_email);
      if (activeMerchant?.name === "Merchant E") {
        formDataa.append("card_nonce", token?.token);
        formDataa.append("zipcode", token?.details?.billing?.postalCode);
        formDataa.append("card_number", token?.details?.card?.last4);
        formDataa.append("cvv", "123");
        formDataa.append(
          "name_on_card",
          data?.submitter_first_name + " " + data?.submitter_last_name
        );
        formDataa.append("expiration_month", token?.details?.card?.expMonth);
        formDataa.append("expiration_year", token?.details?.card?.expYear);
      } else if (activeMerchant?.name === "Merchant D") {
        formDataa.append("token", token?.id);
        formDataa.append("zipcode", data.zipcode);
      } else {
        formDataa.append("zipcode", data.zipcode);
        formDataa.append("card_number", data.card_number);
        formDataa.append("cvv", data.cvv);
        formDataa.append("name_on_card", data.name_on_card);
        formDataa.append("expiration_month", data.expiration_month);
        formDataa.append("expiration_year", data.expiration_year);
      }
      formDataa.append("amount", "5");
      formDataa.append("filling_fee_signature", segnatoure);
      formDataa.append("ein_number", data?.ein_number);
      formDataa.append("state_of_formation", data?.state_of_formation);
      formDataa.append(
        "physical_street_address",
        data?.physical_street_address
      );
      formDataa.append(
        "physical_street_address_line_2",
        data?.physical_street_address_line_2
      );
      formDataa.append("physical_city", data?.physical_city);
      formDataa.append("physical_state", data?.physical_state);
      formDataa.append("physical_zip_code", data?.physical_zip_code);
      formDataa.append("mailing_street_address", data?.mailing_street_address);
      formDataa.append(
        "mailing_street_address_line_2",
        data?.mailing_street_address_line_2
      );
      formDataa.append("mailing_city", data?.mailing_city);
      formDataa.append("mailing_state", data?.mailing_state);
      formDataa.append("mailing_zip_code", data?.mailing_zip_code);
      {
        data?.newShareholders?.map((data, index) => {
          formDataa.append(
            `shareholder[${index}][shareholder_first_name]`,
            data?.First_Name
          );
          formDataa.append(
            `shareholder[${index}][shareholder_last_name]`,
            data?.Last_Name
          );
          formDataa.append(
            `shareholder[${index}][shareholder_street_address]`,
            data?.Street_Address
          );
          formDataa.append(
            `shareholder[${index}][shareholder_street_address_line_2]`,
            data?.street_address_line_2
          );
          formDataa.append(
            `shareholder[${index}][shareholder_city]`,
            data?.city
          );
          formDataa.append(
            `shareholder[${index}][shareholder_state]`,
            data?.country
          );
          formDataa.append(
            `shareholder[${index}][shareholder_zip_code]`,
            data?.zipcode
          );
          formDataa.append(
            `shareholder[${index}][social_security_number]`,
            data?.SocialSecurityNumber
          );
          formDataa.append(
            `shareholder[${index}][ownership_percentage]`,
            data?.Ownership
          );
          formDataa.append(
            `shareholder[${index}][identification_type]`,
            data?.IdentificationType
          );
          formDataa.append(
            `shareholder[${index}][identification_number]`,
            data?.Identification_Number
          );
          formDataa.append(
            `shareholder[${index}][date_of_birth]`,
            data?.dateofbirth
          );
          formDataa.append(
            `shareholder[${index}][social_security_number]`,
            data?.SocialSecurityNumber
          );
          formDataa.append(
            `shareholder[${index}][identification_expiration_date]`,
            data?.identification_expiration_date
          );
          formDataa.append(
            `shareholder[${index}][state_of_issuance]`,
            data?.State_of_Issuance
          );
          formDataa.append(
            `shareholder[${index}][front_image]`,
            data?.Front_Image
          );
          if (data?.Back_Image) {
            formDataa.append(
              `shareholder[${index}][back_image]`,
              data?.Back_Image
            );
          }
        });
      }

      axios
        .post("https://api.deptofbusiness.us/api/contact", formDataa)
        .then((res) => {
          if (res.data.status === true) {
            setPaymentID(res?.data?.payment?.id);
            console.log(res?.data?.payment?.id, "payment id----------->>>>");
            if (activeMerchant?.name === "Merchant F") {
              HelciumRef();
            } else {
              Swal.fire({
                title: "Filing Complete",
                // text: `${res?.data?.message}  `,
                html: ` ${res?.data?.message} `,
                icon: "success",
              });

              // Navigate("/");
              setsloader(false);
            }
          } else {
            toast.error(res.data.message);
            setsloader(false);
          }
        })
        .catch((errors) => {
          toast.error(errors?.response?.data?.message);
          setsloader(false);
          console.log(errors, "err");
        });
    } catch (error) {
      // Handle any payment errors
      console.log(error);
      toast.error("Payment processing failed.");
      return;
    }
  };

  const handleSubmitHelcim = () => {
    // setsloader(true);
    axios
      .get("https://api.deptofbusiness.us/api/helcim-token")
      .then((response) => {
        console.log(response, "helcim");
        if (response?.data?.status) {
          setHelcimToken(response?.data?.checkoutToken);
          // HelciumRef();
        }
      })
      .catch((err) => {
        console.error(err);
        setsloader(false);
      });
  };

  window.addEventListener("message", async (event) => {
    const helcimPayJsIdentifierKey = "helcim-pay-js-" + helcimtoken;
    if (event.data.eventName === helcimPayJsIdentifierKey) {
      console.log(paymentID, "paymentID");

      if (event.data.eventStatus === "ABORTED") {
        console.log(event.data, "failed");
        setsloader(true);
        console.error("Transaction failed!", event.data.eventMessage);

        if (paymentID) {
          const cancelformdata = new FormData();
          cancelformdata.append("payment_id", paymentID);

          axios
            .post("https://api.deptofbusiness.us/api/cancel", cancelformdata)
            .then((response) => {
              console.log(response, "end api response");
              if (response.data.status === true) {
                setPaymentID("");
                setsloader(false);
                Swal.fire({
                  title: "Filing not Complete",
                  // text: `${response?.data?.message}  `,
                  html: ` ${response?.data?.message} `,
                  icon: "error",
                });
                setPaymentID("");
              } else {
                setsloader(false);
                // Swal.fire({
                //   title: "Filing not Complete",
                //   // text: `${response?.data?.message}  `,
                //   html: ` ${response?.data?.message} `,
                //   icon: "error",
                // });
                setPaymentID("");
              }
              console.log(response);
            })
            .catch((error) => {
              setsloader(false);
              console.log(error?.response?.data, "payment errorr cancel api");
              // Swal.fire({
              //   title: "Filing not Complete",
              //   // text: `${response?.data?.message}  `,
              //   html: ` ${error?.response?.data?.message} `,
              //   icon: "error",
              // });
              setPaymentID("");
            });
        } else {
           setTimeout(() => {
            // updateStep(3)
            window.location.reload();
           }, 3000);
        }
      }

      if (event.data.eventStatus === "SUCCESS") {
        console.log(event.data, "success data");
        const sampleData = JSON.parse(event.data.eventMessage);
        console.log(sampleData, "sampleData");
        console.error("Transaction SUCCESS!", event.data.eventMessage);
        setsloader(true);

        const successformdata = new FormData();
        successformdata.append("payment_id", paymentID);
        successformdata.append(
          "invoice_number",
          sampleData?.data?.data.invoiceNumber
        );
        successformdata.append(
          "transaction_id",
          sampleData?.data?.data?.transactionId
        );
        successformdata.append(
          "name_on_card",
          sampleData?.data?.data?.cardHolderName
        );
        successformdata.append("email", data?.filling_fee_email);

        axios
          .post("https://api.deptofbusiness.us/api/success", successformdata)
          .then((response) => {
            if (response.data.status === true) {
              Swal.fire({
                title: "Filing Complete",
                html: ` ${response?.data?.message} `,
                icon: "success",
              }).then((result) => {
                if (result.isConfirmed) {
                  Navigate("/");
                }
              });
            }
            console.log(response);
            setsloader(false);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  });

  return (
    <>
      {loader && <div className="loader" />}

      <div className=" js-active " data-animation="slideVert">
        <div className="inner pb-100">
          <div
            className="wizard-content-item text-center pt-4 pb-4 d-flex justify-content-center align-items-center flex-wrap"
            style={{ gap: "10px" }}
          >
            <img src={logo} width={"100px"} alt="logo" />
            <h2>Signature and Filing Fee</h2>
            {/* <a
              href={`javascript: removeHelcimPayIframe()`}
              ref={closeref}
              className="d-none"
            >
              close
            </a> */}
          </div>
          <div className="wizard-content-form">
            <div className="wizard-form-field">
              <div className="row">
                <div className="col-md-6">
                  <div className="wizard-form-input position-relative form-group has-float-label">
                    <i
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="If you want your invoice address to a compnay. Leave blank to use full name"
                      className="fa fa-info"
                    />
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      value={data?.filling_fee_first_name ? data?.filling_fee_first_name : ''}
                      name="filling_fee_first_name"
                      placeholder="First Name"
                    />
                    <label>First Name</label>
                  </div>
                  {emptyFields.includes("submitter_last_name") && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="wizard-form-input position-relative form-group has-float-label">
                    <i
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="If you want your invoice address to a compnay. Leave blank to use full name"
                      className="fa fa-info"
                    />
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      value={data?.filling_fee_last_name ? data?.filling_fee_last_name : ''}
                      name="filling_fee_last_name"
                      placeholder="Last Name"
                    />
                    <label>Last Name</label>
                  </div>
                  {emptyFields.includes("submitter_last_name") && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="wizard-form-input position-relative form-group has-float-label">
                    <i
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="If you want your invoice address to a compnay. Leave blank to use full name"
                      className="fa fa-info"
                    />
                    <input
                      type=""
                      value={data?.filling_fee_email ? data?.filling_fee_email : ''}
                      onChange={handleChange}
                      name="filling_fee_email"
                      className="form-control"
                      placeholder="Email"
                    />
                    <label>Email*</label>
                  </div>
                  {emptyFields.includes("submitter_last_name") && (
                    <p className="text-danger">This field is required.</p>
                  )}
                </div>

                {activeMerchant?.name !== "Merchant A" &&
                  activeMerchant?.name !== "Merchant B" &&
                  activeMerchant?.name !== "Merchant C" &&
                  activeMerchant?.name !== "Merchant D" && (
                    <div className="col-md-6">
                      <h2 className="mt-3" style={{ color: "#012367" }}>
                        Filing Fee: $78
                      </h2>
                    </div>
                  )}

                {activeMerchant?.name !== "Merchant E" &&
                  activeMerchant?.name !== "Merchant F" && (
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type=""
                          onChange={handleChange}
                          name="zipcode"
                          value={data?.zipcode ? data?.zipcode : ''}
                          className="form-control"
                          placeholder="Zip Code"
                        />
                        <label>Zip Code*</label>
                      </div>
                      {emptyFields.includes("zipcode") && (
                        <p className="text-danger">Zip Code is required.</p>
                      )}
                    </div>
                  )}
              </div>


              {activeMerchant?.name === "Merchant E" ? (
                <>
                  <div className="badge-selection">
                    <h3>
                      Signature* (By signing you confirm that all information in
                      this filing is correct and complete to the best of your
                      knowledge. You understand that by submitting incomplete,
                      false or deceptive information you may be face
                      penalties.)*
                    </h3>
                    <div className="border">
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                          border: "2px solid black !important",
                          height: 400,
                          className: "signature-canvas w-100",
                        }}
                        onEnd={() => saveSignature()}
                        ref={signatureRef}
                      />
                    </div>
                    <div className="d-flex justify-content-around">
                      <button
                        className="custom-btn mt-3 mb-3"
                        onClick={clearSignature}
                      >
                        Clear Signature
                      </button>
                      {/* <button className="custom-btn" onClick={saveSignature}>
                    Save Signature
                  </button> */}
                    </div>
                  </div>

                  <PaymentForm
                    applicationId="sq0idp-KPzXPV7F8gYaJBWCUZe4HA"
                    locationId="L4N55261S87RA"
                    cardTokenizeResponseReceived={async (token, buyer) => {
                      await handleSubmit(token);
                    }}
                  >
                    <CreditCard />
                  </PaymentForm>
                </>
              ) : activeMerchant?.name === "Merchant F" ||
                activeMerchant?.name === "Merchant D" ? (
                <div className="badge-selection">
                  <h3>
                    Signature* (By signing you confirm that all information in
                    this filing is correct and complete to the best of your
                    knowledge. You understand that by submitting incomplete,
                    false or deceptive information you may be face penalties.)*
                  </h3>
                  <div className="border">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{
                        border: "2px solid black !important",
                        height: 400,
                        className: "signature-canvas w-100",
                      }}
                      onEnd={() => saveSignature()}
                      ref={signatureRef}
                    />
                  </div>
                  <div className="d-flex justify-content-around">
                    <button
                      className="custom-btn mt-3 mb-3"
                      onClick={clearSignature}
                    >
                      Clear Signature
                    </button>
                    {/* <button className="custom-btn" onClick={saveSignature}>
                   Save Signature
                 </button> */}
                  </div>
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="number"
                          className="form-control"
                          onChange={handleChange}
                          name="card_number"
                          placeholder="Card Number"
                        />
                        <label>Card Number</label>
                      </div>
                      {emptyFields.includes("submitter_last_name") && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="number"
                          className="form-control"
                          onChange={handleChange}
                          name="cvv"
                          placeholder="Card cvv"
                        />
                        <label>Card cvv</label>
                      </div>
                      {emptyFields.includes("submitter_last_name") && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          name="name_on_card"
                          placeholder="Name On Card"
                        />
                        <label>Name On card</label>
                      </div>
                      {emptyFields.includes("submitter_last_name") && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                        <select
                          id="country"
                          onChange={handleChange}
                          name="expiration_month"
                          className="form-control"
                        >
                          <option selected disabled value="">
                            Card expiration month*
                          </option>
                          {monthsArray.map((data, index) => {
                            return (
                              <option key={index} value={data.value}>
                                {data.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {emptyFields.includes("submitter_last_name") && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="number"
                          className="form-control"
                          onChange={handleChange}
                          max={4}
                          name="expiration_year"
                          placeholder="YYYY"
                        />
                        <label>Card expiration Year</label>
                      </div>
                      {emptyFields.includes("submitter_last_name") && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>

                    <div className="col-md-6">
                      <h2 className="mt-3" style={{ color: "#012367" }}>
                        Filing Fee: $78
                      </h2>
                    </div>
                  </div>
                  <div className="badge-selection">
                    <h3>
                      Signature* (By signing you confirm that all information in
                      this filing is correct and complete to the best of your
                      knowledge. You understand that by submitting incomplete,
                      false or deceptive information you may be face
                      penalties.)*
                    </h3>
                    <div className="border">
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                          border: "2px solid black !important",
                          height: 400,
                          className: "signature-canvas w-100",
                        }}
                        onEnd={() => saveSignature()}
                        ref={signatureRef}
                      />
                    </div>
                    <div className="d-flex justify-content-around">
                      <button
                        className="custom-btn mt-3 mb-3"
                        onClick={clearSignature}
                      >
                        Clear Signature
                      </button>
                      {/* <button className="custom-btn" onClick={saveSignature}>
                    Save Signature
                  </button> */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={open ? "mt-4 d-none" : "mt-4"}>
            <div className="actions">
              <ul className="d-flex justify-content-center align-items-center flex-wrap">
                <li onClick={() => updateStep(3)}>
                  <span className="js-btn-prev" title="BACK">
                    <i className="fa fa-arrow-left" /> BACK
                  </span>
                </li>
                <a
                  href={`javascript: appendHelcimPayIframe('${helcimtoken}', false)`}
                  ref={ref}
                  className="d-none"
                >
                  Pay Now
                </a>
                {activeMerchant?.name !== "Merchant E" &&
                  activeMerchant?.name !== "Merchant D" && (
                    <li>
                      <span
                        onClick={handleSubmit}
                        className="js-btn-next"
                        title="Submit"
                      >
                        Submit
                      </span>
                    </li>
                  )}

                {activeMerchant?.name === "Merchant D" &&
                  (Fields?.length > 0 ? (
                    <li>
                      <span
                        className="js-btn-next"
                        title="Check"
                        onClick={checkField}
                      >
                        check Field <i className="fa fa-arrow-right" />
                      </span>
                    </li>
                  ) : (
                    <StripeCheckout
                      token={handleSubmit}
                      stripeKey="pk_live_51OHTmMCffx7FLXmRKglRrdzpTEWHeDRVaQlnhBZM3kkfKryl1Hg60Vi08wY0afX25TgVKS4FJjmxSkwNr8rFJaTX00l58ZG49p"
                      amount={78 * 100}
                    >
                      <li>
                        <span className="js-btn-next" title="Submit">
                          Submit
                        </span>
                      </li>
                    </StripeCheckout>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Fromfour;
