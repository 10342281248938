import { BrowserRouter, Route, Routes } from 'react-router-dom';
import From from './From/From';
import Landingpage from './Landingpage';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Landingpage/>}/>
        <Route path='/form' element={<From/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
