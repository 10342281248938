import { createContext } from "react";

const FormContext = createContext({
    step: 1,
    data: {},
    dataHistory: [],
    error: null,
    updateStep: (step) => { },
    updateData: (data) => { },
    validateForm: () => { },
});

export default FormContext;