import React, { useEffect, useState } from "react";
import FormOne from "./FormOne";
import Fromtwo from "./Fromtwo";
import FromThree from "./FromThree";
import Fromfour from "./Fromfour";
import { Toaster } from "react-hot-toast";
import FormContext from "./FormContext";
import axios from "axios";

const From = () => {

  function handlegetActiveMerchant() {
    try {
      axios
        .get(`https://api.deptofbusiness.us/api/merchant?status=1`)
        .then((response) => {
          console.log(response?.data?.merchant[0]);
          localStorage.setItem("activeMerchant",JSON.stringify(response?.data?.merchant[0]));
        })
        .catch((e) => {
          console.error(e);
        });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    handlegetActiveMerchant()
  }, []);

  

  const [context, setContext] = useState({
    step: 1,
    data: {},
    dataHistory: [],
    error: null,
  });

  const updateStep = (step) => {
    setContext((prevState) => ({
      ...prevState,
      dataHistory: [...prevState.dataHistory, prevState.data],
      step,
    }));
  };

  const updateData = (data) => {
    setContext((prevState) => ({ ...prevState, data }));
  };

  const validateForm = () => {
    // Implement your validation logic here based on context.data
    // Return true if valid, false otherwise and set context.error
    // Example:
    if (!context.data.name || !context.data.email) {
      setContext((prevState) => ({
        ...prevState,
        error: "Please fill in all required fields.",
      }));
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      // Submit context.data to API
      console.log("Submitting form data:", context.data);
    }
  };

  // useEffect(() => {
  //   const storedData = JSON.parse(localStorage.getItem("formData"));
  //   if (storedData) {
  //     setContext((prevState) => ({ ...prevState, data: storedData }));
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem("formData", JSON.stringify(context.data));
  // }, [context.data]);


  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="wrapper clearfix">
        <div className="multisteps-form">
          <div className="row">
            <div className="col-12 col-lg-12 m-auto">
              <div className="multisteps-form__form clearfix">
              <FormContext.Provider value={{ ...context, updateStep, updateData, validateForm }}>
                {context.step === 1 && (
                  <FormOne />
                )}
                {context.step === 2 && (
                  <Fromtwo />
                )}
                {context.step === 3 && (
                  <FromThree />
                )}
                {context.step === 4 && (
                  <Fromfour onClick={handleSubmit}/>
                )}
                </FormContext.Provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default From;
