import React, { useContext, useEffect, useState } from "react";
import logo from "../image/logo.png";
import FormContext from "./FormContext";

const FromThree = () => {

  useEffect(() => {
    window.scroll(0, 0)
  }, [])


  const { data, updateData, updateStep } = useContext(FormContext);
  const [emptyFields, setEmptyFields] = useState([]);
  const [shareholders, setShareholders] = useState(data?.newShareholders || [{}]);
  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const [addanother, setaddanother] = useState(true);
  const [, setshow] = useState(false);
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  const handleshowAddbutton = (e) => {
    if (e.target.value === "yes") {
      setshow(true);
    } else {
      setshow(false);
    }
  };

  const handleChange = (e, shareholderIndex, field) => {
    const newShareholders = [...shareholders];
    newShareholders[shareholderIndex] = {
      ...newShareholders[shareholderIndex],
      [field]: e.target.value,
    };
    updateData({
      ...data,
      newShareholders
    });
    setShareholders(newShareholders);
  };

  const handleFileChange = (e, shareholderIndex, field) => {
    const newShareholders = [...shareholders];
    newShareholders[shareholderIndex] = {
      ...newShareholders[shareholderIndex],
      [field]: e.target.files[0],
    };
    updateData({
      ...data,
      newShareholders
    });
    setShareholders(newShareholders);
    setaddanother(false);
  };

  const addShareholder = () => {
    setShareholders([...shareholders, {}]);
    setshow(false);
    setaddanother(true);
  };

  const removeShareholder = (index) => {
    setShareholders((prevShareholders) =>
      prevShareholders.filter((_, i) => i !== index)
    );
    setaddanother(false);
  };

  const handleNext = () => {

    const requiredFields = [
      'First_Name',
      'Last_Name',
      'Street_Address',
      'city',
      'country',
      'zipcode',
      'Ownership',
      'dateofbirth',
      'SocialSecurityNumber',
      'IdentificationType',
      'Identification_Number',
      'identification_expiration_date',
      'State_of_Issuance',
      'Front_Image',
      // 'Back_Image',
    ];


    const emptyFields = shareholders.map((shareholder) => {
      return requiredFields.filter((field) => !shareholder[field] || shareholder[field] === '');
    }).flat(1);


    if (emptyFields.length > 0) {
      // toast.error(`Please fill in the following fields: ${emptyFields.join(', ')}`);
      setEmptyFields(emptyFields);
      return;
    }
    updateData({
      ...data,
      // shareholders,
    });
    updateStep(4);

    console.log(data, "data")
  };

  const handleBack = () => {
    updateStep(2);
  };

  console.log(emptyFields, "emptyFields")



  return (
    <div className=" js-active " data-animation="slideVert">
      <div className="inner pb-100">



        {shareholders?.map((share, index) => {
          return (
            <>
              <div className="wizard-content-item text-center pt-4 pb-4">
                <div className=" d-flex justify-content-center align-items-center flex-wrap" style={{ gap: '20px' }}>
                  <img src={logo} width={"100px"} alt="logo" />
                  <h2>
                    Shareholder / Owner
                    {index > 0 && (
                      <button
                        className="btn btn-danger float-right ml-5"
                        onClick={() => removeShareholder(index)}
                      >
                        Remove
                      </button>
                    )}
                  </h2>
                </div>
                {index < 1 && (
                  <p style={{ fontSize: "18px" }}>
                    You are required to report all owners and/or shareholders
                    with 25% or greater ownership
                  </p>
                )}
              </div>
              <div className="wizard-content-form">
                <div className="wizard-form-field">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="text"
                          className="form-control"
                          name="First_Name"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.First_Name}
                          placeholder="First Name"
                          onChange={(e) => handleChange(e, index, "First_Name")}
                        />
                        <label>First Name</label>
                      </div>
                      {emptyFields.includes('First_Name') && (
                        <p className="text-danger">First Name is required.</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="text"
                          className="form-control"
                          name="Last_Name"
                          placeholder="Last Name"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.Last_Name}
                          onChange={(e) => handleChange(e, index, "Last_Name")}
                        />
                        <label>Last Name</label>
                      </div>
                      {emptyFields.includes('Last_Name') && (
                        <p className="text-danger">Last Name is required.</p>
                      )}
                    </div>
                    <div className="col-12  pt-4 pb-4">
                      <h3>Shareholder 1 Address*</h3>
                    </div>

                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <input
                          type="text"
                          className="form-control"
                          name="Street_Address"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.Street_Address}
                          onChange={(e) =>
                            handleChange(e, index, "Street_Address")
                          }
                          placeholder="Street Address"
                        />
                        <label>Street Address</label>
                      </div>
                      {emptyFields.includes('Street_Address') && (
                        <p className="text-danger">Street Address is required.</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <input
                          type="text"
                          className="form-control"
                          name="street_address_line_2"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.street_address_line_2}
                          onChange={(e) =>
                            handleChange(e, index, "street_address_line_2")
                          }
                          placeholder=" Street Address Line 2"
                        />
                        <label>Street Address Line 2</label>
                       
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <input
                          type="text"
                          className="form-control"
                          name="city"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.city}
                          onChange={(e) => handleChange(e, index, "city")}
                          placeholder="City"
                        />
                        <label>City</label>
                      </div>
                      {emptyFields.includes('city') && (
                        <p className="text-danger">city is required.</p>
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                        <select
                          id="country"
                          name="country"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.country}
                          onChange={(e) => handleChange(e, index, "country")}
                          className="form-control"
                        >
                          <option selected value="state">
                            State
                          </option>
                          {usStates.map((data, index) => {
                            return (
                              <option key={index} value={data}>
                                {data}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {emptyFields.includes('country') && (
                        <p className="text-danger">country is required.</p>
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <input
                          type="number"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.zipcode}
                          onChange={(e) => handleChange(e, index, "zipcode")}
                          className="form-control"
                          name="zipcode"
                          placeholder="Zip/Postal Code"
                        />
                        <label>Zip/Postal Code</label>
                      </div>
                      {emptyFields.includes('zipcode') && (
                        <p className="text-danger">zipcode is required.</p>
                      )}
                    </div>

                  </div>

                  <div className="row">
                    <div className="col-12 pt-4 pb-4">
                      <h2>Shareholder Other Details</h2>
                    </div>

                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="number"
                          onChange={(e) => handleChange(e, index, "Ownership")}
                          className="form-control"
                          name="Ownership"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.Ownership}
                          placeholder="Ownership In Percentage"
                        />
                        <label>Ownership</label>
                      </div>
                      {emptyFields.includes('Ownership') && (
                        <p className="text-danger">Ownership is required.</p>
                      )}
                    </div>

                    <div className="col-md-4">
                      <p>Date of Birth</p>
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <input
                          type="date"
                          style={{ height: "60px" }}
                          onChange={(e) =>
                            handleChange(e, index, "dateofbirth")
                          }
                          className="form-control"
                          name="dateofbirth"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.dateofbirth}
                          placeholder="Date of Birth*"
                        />
                      </div>
                      {emptyFields.includes('dateofbirth') && (
                        <p className="text-danger">date of birth is required.</p>
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="text"
                          className="form-control"
                          name="SocialSecurityNumber"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.SocialSecurityNumber}
                          onChange={(e) =>
                            handleChange(e, index, "SocialSecurityNumber")
                          }
                          placeholder="Social Security Number*"
                        />
                        <label>Social Security Number*</label>
                      </div>
                      {emptyFields.includes('SocialSecurityNumber') && (
                        <p className="text-danger">Social Security Number is required.</p>
                      )}
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-12 pt-4 pb-4">
                      <h2>Identification Type*</h2>
                    </div>

                    <div className="col-12">
                      <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                        <select
                          id="country"
                          name="IdentificationType"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.IdentificationType}
                          onChange={(e) =>
                            handleChange(e, index, "IdentificationType")
                          }
                          className="form-control"
                        >
                          <option selected disabled value="Identification Type">
                            Identification Type
                          </option>
                          <option value={"Driver’sLicense"}>
                            Driver’s License
                          </option>
                          <option value={"IdentificationCard"}>
                            Identification Card
                          </option>
                          <option value={"Passport"}>Passport</option>
                        </select>
                      </div>
                      {emptyFields.includes('IdentificationType') && (
                        <p className="text-danger">Identification Type is required.</p>
                      )}
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <i
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="If you want your invoice address to a compnay. Leave blank to use full name"
                          className="fa fa-info"
                        />
                        <input
                          type="text"
                          name="Identification_Number"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.Identification_Number}
                          onChange={(e) =>
                            handleChange(e, index, "Identification_Number")
                          }
                          className="form-control"
                          placeholder="Identification Number*"
                        />
                        <label>Identification Numbers</label>
                      </div>
                      {emptyFields.includes('Identification_Number') && (
                        <p className="text-danger">Identification Number is required.</p>
                      )}
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <p>Identification Expiration Date</p>
                        <input
                          type="date"
                          name="identification_expiration_date"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.identification_expiration_date}
                          style={{ height: "60px" }}
                          onChange={(e) =>
                            handleChange(
                              e,
                              index,
                              "identification_expiration_date"
                            )
                          }
                          className="form-control"
                          placeholder="Identification Number*"
                        />
                      </div>
                      {emptyFields.includes('identification_expiration_date') && (
                        <p className="text-danger">identification expiration date is required.</p>
                      )}
                    </div>
                    <div className="col-md-4">
                      <div className="wizard-form-input position-relative form-group has-float-label mt-0 n-select-option">
                        <select
                          id="country"
                          name="State_of_Issuance"
                          defaultValue={data?.newShareholders && data?.newShareholders[index]?.State_of_Issuance}
                          onChange={(e) =>
                            handleChange(e, index, "State_of_Issuance")
                          }
                          className="form-control"
                        >
                          <option selected disabled value="State of Issuance">
                            State of Issuance*
                          </option>
                          {usStates.map((data, index) => {
                            return (
                              <option key={index} value={data}>
                                {data}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {emptyFields.includes('State_of_Issuance') && (
                        <p className="text-danger">State of Issuance is required.</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="wizard-form-input position-relative form-group has-float-label">
                        <h5>Front of Identification*</h5>
                        <input
                          type="file"
                          style={{ height: "50px" }}
                          onChange={(e) =>
                            handleFileChange(e, index, "Front_Image")
                          }
                          name="Front_Image"
                          className="form-control mt-2"
                        />
                      </div>
                      {emptyFields.includes('Front_Image') && (
                        <p className="text-danger">Front Image is required.</p>
                      )}
                    </div>
                    {shareholders[index]?.IdentificationType ===
                      "Passport" ? null : (
                      <div className="col-md-6">
                        <div className="wizard-form-input position-relative form-group has-float-label">
                          <h5>Back of Identification*</h5>
                          <input
                            type="file"
                            style={{ height: "50px" }}
                            onChange={(e) =>
                              handleFileChange(e, index, "Back_Image")
                            }
                            name="Back_Image"
                            className="form-control mt-2"
                          />
                        </div>
                      
                      </div>
                    )}
                  </div>
                </div>
                <div className="badge-selection">
                  <h3>
                    Do you have additional shareholders / owners with 25% or
                    greater ownership?*
                  </h3>

                  <label>
                    <input
                      onChange={addShareholder}
                      value="yes"
                      type="radio"
                      className="disable"
                      disabled={addanother}
                      name="addbutton"
                    />
                    <span className="checkmark">Yes</span>
                  </label>
                  <label>
                    <input
                      onChange={handleshowAddbutton}
                      value="no"
                      disabled={addanother}
                      type="radio"
                      name="addbutton"
                    />
                    <span className="checkmark">No</span>
                  </label>
                </div>
              </div>
            </>
          );
        })}

        <div className="mt-4">
          <div>
            <ul className="d-flex justify-content-center align-items-center flex-wrap" style={{ gap: "10px" }}>
              {/* {show && shareholders.length !== 4 && (
                <li onClick={addShareholder}>
                  <button className="custom-btn" title="Add Shareholder">
                    <i className="fa fa-arrow-left" />
                    Add Shareholder
                  </button>
                </li>
              )} */}

              <li onClick={handleBack}>
                <button className="custom-btn" title="BACK">
                  <i className="fa fa-arrow-left" /> BACK
                </button>
              </li>
              <li onClick={handleNext}>
                <button className="custom-btn" title="NEXT">
                  NEXT <i className="fa fa-arrow-right" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FromThree;
