import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import logo from '../image/logo.png'
import SignatureCanvas from "react-signature-canvas";
import FormContext from "./FormContext";



const FormOne = () => {

    const { data, updateData, updateStep } = useContext(FormContext);
    const signatureRef = useRef();
    const [emptyFields, setEmptyFields] = useState([]);
    const [signature, setsignature] = useState("");
    useEffect(() => {
     window.scroll(0, 0)
    }, [])

  const handleChange = (event) => {
    updateData({ ...data, [event.target.name]: event.target.value });
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    const signatureData = signatureRef.current.toDataURL();
    setsignature(signatureData);
  };

  const handleNext = () => {
    const requiredFields = ['submitter_first_name', 'submitter_last_name', 'submitter_business_name', 'submitter_email', 'submitter_role'];

    const emptyFields = requiredFields.filter((field) => !data[field] || data[field] === '');

    if (emptyFields.length > 0) {
      toast.error(`Please fill in the following fields: ${emptyFields.join(', ')}`);
      setEmptyFields(emptyFields);
      return;
    } else if (!signature) {
      toast.error('Please sign the document to proceed.');
      setEmptyFields(['signature'])
      return;
    }

    updateData({
      ...data,
      signature,
    });
    updateStep(2);
  };


  console.log(data, "form 1")
  return (
    <>

      <div className=" js-active " data-animation="slideVert">
        <div className="inner pb-100">
          <div className="wizard-content-item text-center pt-4 pb-4 d-flex justify-content-center align-items-center flex-wrap " style={{ gap: '10px' }}>
            <img src={logo} width={'100px'} alt="logo" />
            <h2>Submitter Information</h2>
          </div>
          <div className="wizard-content-form">
            <div className="wizard-form-field">
              <div className="wizard-form-input position-relative form-group has-float-label">
                <input
                  type="text"
                  name="notice_id"
                  className="form-control"
                  onChange={handleChange}

                  placeholder="Notice ID (Leave Blank If You Didn’t Receive A Notice)"
                />
                <label>
                  Notice ID # (Leave Blank If You Didn’t Receive A Notice)
                </label>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="wizard-form-input position-relative form-group has-float-label">
                    <i
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="If you want your invoice address to a compnay. Leave blank to use full name"
                      className="fa fa-info"
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="submitter_first_name"
                      value={data?.submitter_first_name}
                      defaultValue={data.submitter_first_name}
                      placeholder="First Name"
                      onChange={handleChange}
                    />
                    <label>First Name</label>
                  </div>
                  {emptyFields.includes('submitter_first_name') && (
                    <p className="text-danger">First Name is required.</p>
                  )}
                </div>

                <div className="col-md-6">
                  <div className="wizard-form-input position-relative form-group has-float-label">
                    <i
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="If you want your invoice address to a compnay. Leave blank to use full name"
                      className="fa fa-info"
                    />
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      value={data?.submitter_last_name}
                      defaultValue={data.submitter_last_name}
                      name="submitter_last_name"
                      placeholder="Last Name"
                    />
                    <label>Last Name</label>
                  </div>
                  {emptyFields.includes('submitter_last_name') && (
                    <p className="text-danger">Last Name is required.</p>
                  )}
                </div>

              </div>
              <div className="wizard-form-input position-relative form-group has-float-label">
                <i
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="If you want your invoice address to a compnay. Leave blank to use full name"
                  className="fa fa-info"
                />
                <input
                  type="text"
                  className="form-control"
                  name="submitter_business_name"
                  value={data?.submitter_business_name}
                  defaultValue={data.submitter_business_name}
                  onChange={handleChange}
                  placeholder="Business Name"
                />
                <label>Business Name</label>
              </div>
              {emptyFields.includes('submitter_business_name') && (
                <p className="text-danger">Business Name is required.</p>
              )}
              <div className="wizard-form-input position-relative form-group has-float-label">
                <i
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="If you want your invoice address to a compnay. Leave blank to use full name"
                  className="fa fa-info"
                />
                <input
                  type="email"
                  className="form-control"
                  name="submitter_email"
                  value={data?.submitter_email}
                  defaultValue={data.submitter_email}
                  onChange={handleChange}
                  placeholder="Email Address"
                />
                <label>Email Address</label>
              </div>
              {emptyFields.includes('submitter_email') && (
                <p className="text-danger">Email is required.</p>
              )}
            </div>
            <div className="badge-selection">
              <h3>Submitter Role*</h3>
              <label>
                <input
                  onChange={handleChange}
                  value="Owner"
                  type="radio"
                  name="submitter_role"
                  checked={data?.submitter_role}
                  defaultChecked={data?.submitter_role}
                />
                <span className="checkmark">Owner</span>
              </label>
              <label>
                <input
                  onChange={handleChange}
                  value="Officer"
                  type="radio"
                  name="submitter_role"
                />
                <span className="checkmark">Officer</span>
              </label>
              <label>
                <input
                  onChange={handleChange}
                  value="Paid-Preparer"
                  type="radio"
                  name="submitter_role"
                />
                <span className="checkmark">Paid Preparer</span>
              </label>
              {emptyFields.includes('submitter_role') && (
                <p className="text-danger">Role is required.</p>
              )}
            </div>


            <div className="badge-selection mt-3">
              <h3>
                Signature* (By signing you are confirming that you are authorized
                by "the Company" to act on its behalf.)*
              </h3>
              <div style={{ border: "3px solid black" }}>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    // width: "500px",
                    border: "2px solid black !important",
                    height: 400,
                    className: "signature-canvas w-100",
                  }}
                  onEnd={() => saveSignature()}
                  ref={signatureRef}
                />


              </div>
              <div className="d-flex justify-content-around">
                <button className="custom-btn mt-3" onClick={clearSignature}>
                  Clear Signature
                </button>
                {/* <button className="custom-btn" onClick={saveSignature}>
                    Save Signature
                  </button> */}
              </div>
              {emptyFields.includes('signature') && (
                <p className="text-danger">Signature is required.</p>
              )}

            </div>
          </div>
          <div className="mt-5">
            <div className="actions">
              <ul>
                <li onClick={() =>
                  handleNext()
                }>
                  <span className="js-btn-next" title="NEXT">
                    NEXT <i className="fa fa-arrow-right" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default FormOne;
